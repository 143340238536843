import { global } from ":global";
import { _Firebase } from "./_model";
import { APoll } from "../_model";

export async function addNew(props: { polls: APoll[]; new: APoll }) {
  const polls = props.polls ?? [];
  const id = String(polls.length);
  // clouds.push(props.new)
  return global.fb.rtdb.set({
    path: `/poll/${id}`,
    value: props.new,
  });
}

export async function updatePoll(props: { id: string, poll:{ question: string, choices: {title:string , value:number}[] }}) {
  const id = String(props.id);
  return global.fb.rtdb.set({
    path: `/poll/${id}/`,
    value: props.poll,
  });
}

export async function onAllChanged(
  callback: _Firebase.OnPollUpdateProps["onUpdate"]
) 
{
  global.fb.rtdb.onValueChanged({
    path: "/poll/",
    callback: (snap) => {
      callback?.(snap.val());
    },
  });
}

export async function deleteAll() {
  global.fb.rtdb.set({
    path: "/poll/",
    value: [],
  });
}

export async function deleteID(id: number | string) {
  global.fb.rtdb.set({
    path: `/poll/${String(id)}`,
    value: null,
  });
}
